import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Deflation = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Deflation – Definition, Ursachen, Folgen" showCalc={false} />
            <Article>
                <p>
                    Beim müßigen Durchblättern des Wirtschaftsteils diverser Zeitungen oder bei der konzentrierten
                    Recherche für eine Finanzierung stößt man zuweilen auf den Begriff der Deflation. Im Unterschied zu
                    seinem Gegenstück, der{" "}
                    <Link to="/artikel/inflation/" target="_blank" rel="noreferrer noopener">
                        Inflation
                    </Link>
                    , bietet dieses Phänomen meist nicht Anlass zu pessimistischen Prognosen und erregt auch bei den
                    Lesenden nicht dasselbe Maß an Angst. Doch ist Deflation tatsächlich ein tendenziell neutrales oder
                    sogar positives Phänomen oder sollte ihr mit derselben Skepsis begegnet werden, der man auch ihrer
                    großen Schwester, der Inflation, entgegenbringt?
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist Deflation?</h2>
                <p>
                    Von Deflation wird dann gesprochen, wenn das generelle Preisniveau einer Volkswirtschaft sinkt.
                    Umgekehrt steigt dadurch die Kaufkraft des Geldes – man kann für dieselbe Summe nun eine höhere Zahl
                    an Waren kaufen und auch Dienstleistungen werden günstiger. Statistisch schlägt sich eine Deflation
                    zudem in einer negativen Inflationsrate nieder. Auf wirtschaftlicher Sicht ist dabei zentral, dass
                    erst bei einer Inflationsrate, die über einen Zeitraum von mehr als drei Monaten deutlich im
                    negativen Bereich liegt, von Deflation gesprochen werden kann. Temporäre Inflationsraten von -0,1
                    bis -0,3 % lassen sich meist durch Preisverzerrungen auf dem Öl-, Energie- und Lebensmittelmarkt
                    erklären.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wodurch kommt es zu einer Deflation?</h2>
                <p>
                    Eine Deflation wird ausgelöst, wenn es zu einer Nachfragelücke kommt, das Angebot an Waren und
                    Dienstleistungen also allgemein größer ist als die Nachfrage. Darüber hinaus kann sie durch einen
                    Rückgang der in einem Wirtschaftsraum zirkulierenden Geldmenge bedingt werden. Als Ursache der
                    sinkenden Preise kommt Folgendes infrage:
                </p>
                <ul>
                    <li>
                        <strong>Nachfragerückgang:</strong> Aufgrund einer bestehenden oder prognostizierten Rezession
                        sind Verbraucher:innen und Unternehmen vorsichtig, wenn es um die Ausgabe bzw. die Investition
                        größerer Summen geht. Dadurch sinkt das allgemeine Nachfrageniveau. Auch Einsparungsmaßnahmen
                        vonseiten des Staates können zu einem Rückgang der Nachfrage führen. Darüber hinaus wirkt sich
                        auch eine sinkende Exportrate negativ auf das Nachfrageniveau einer Volkswirtschaft aus.
                    </li>
                    <li>
                        <strong>Platzende Spekulationsblasen:</strong> Diese bedingen eine sogenannte
                        Vermögensdeflation, bei der die betreffenden Objekte (häufig Immobilien, siehe{" "}
                        <Link to="/artikel/immobilienblase/" target="_blank" rel="noreferrer noopener">
                            Immobilienblase
                        </Link>
                        ), die ursprünglich durch einen{" "}
                        <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                            Kredit
                        </Link>{" "}
                        finanziert wurden, bei konstanter Kreditsumme drastisch an Wert verlieren. Durch die
                        Überschuldung eines Großteils der Käufer:innen wird eine geringere Menge neuer Krediten
                        vergeben, als an bestehenden zurückgezahlt wird. Infolgedessen ist insgesamt weniger Kapital im
                        Umlauf; das vorhandene verfügt somit über eine höhere Kaufkraft.
                    </li>
                    <li>
                        <strong>Zunahmen in der Produktivität:</strong> Durch technische Innovationen kann die Effizienz
                        in der Produktion in manchen Fällen derart drastisch zunehmen, dass die Zahl der erzeugten
                        Produkte die Nachfrage übersteigt. Dies ist allerdings ein Ausnahmefall.
                    </li>
                </ul>
                <hr />

                <h2>Was sind die Folgen einer Deflation?</h2>
                <p>
                    Eine zunehmende Steigerung der Kaufkraft setzt eine Dynamik in Gang, die an eine Lawine erinnert: Je
                    länger der Effekt andauert, umso stärker wird er. Die Folge ist eine Rezession, die bedingt wird
                    durch
                </p>
                <ul>
                    <li>geringere Profite von Unternehmen aufgrund sinkender Preise</li>
                    <li>Einsparungsmaßnahmen, die zu niedrigeren Gehältern und der Entlassung von Personal führt</li>
                    <li>Steigenden Arbeitslosigkeit und daraus resultierender Rückgang im Konsum</li>
                    <li>
                        Geringere Investitionen vonseiten der Unternehmen Infolge dieses Teufelskreises kommt es zu
                        einem weiteren negativen Effekt: Auch im Privatbereich werden größere Ausgaben aufgeschoben, da
                        die entsprechenden Produkte und Dienstleistungen zunehmend billiger werden. Durch die dadurch
                        bedingte Kapitalakkumulation wird die Deflation weiter verstärkt.
                    </li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"deflation"}></BreadcrumbList>
            <ArticleStructuredData page={"deflation"} heading={"Deflation – Definition, Ursachen, Folgen"} />
        </Layout>
    );
};

export default Deflation;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "page.artikel.deflation"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
